import React from "react";
import "./about.css";
import { Container, Row, Col } from "reactstrap";
import aboutImg from "../../assests/images/about-us.png";
import CountUp from "react-countup";
import "./about.css";

const AboutUs = () => {
  return (
    <section>
      <Container>
        <Row>
          <Col lg="6" md="6">
            <div className="about__img">
              <img src={aboutImg} alt="" className="w-100" />
            </div>
          </Col>

          <Col lg="6" md="6">
            <div className="about__content">
              <h2 style={{ marginTop: "4%", marginBottom: "4%" }}>About Us</h2>
              <p>
                We are Software Professionals with 6+ Years of experience in real time projects with companies
                like Amazon, Oracle, Zeta, Capgemini, TechMahindra and Infosys.
              </p>

              <p>
                Strong Computer Science Professionals from IIT and IIIT And Management Professionals from IIM.
              </p>
              <p>
                Had good experience in mentoring students in various technlogies like Full stack development with Java,
                Frontend Development with React, Backend With Node.js, Backend with Golang and DevOps with AWS.
              </p>

              <div className="about__counter">
                <div className=" d-flex gap-5 align-items-center">
                  <div className="single__counter">
                    <span className="counter">
                      <CountUp start={0} end={5} duration={1} suffix=" + " />
                    </span>

                    <p className="counter__title">Completed Batches</p>
                  </div>

                  <div className="single__counter">
                    <span className="counter">
                      <CountUp start={0} end={200} duration={10} suffix="+" />
                    </span>

                    <p className="counter__title">Total Students</p>
                  </div>
                </div>

                {/* <div className=" d-flex gap-5 align-items-center">
                  <div className="single__counter">
                    <span className="counter">
                      <CountUp start={0} end={95} duration={5} suffix="M" />
                    </span>

                    <p className="counter__title">Ideas Raised Funds</p>
                  </div>

                  <div className="single__counter">
                    <span className="counter">
                      <CountUp start={0} end={5} duration={5} suffix="K" />
                    </span>

                    <p className="counter__title">Categories Served</p>
                  </div>
                </div> */}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default AboutUs;
