import React, { useEffect } from 'react'
import "./Team.css"
import man from '../../assests/images/man.png'
import women from '../../assests/images/women.png'

const teamMembers = [
    {
        "id": 1,
        "name": "Dr Siva Singu",
        "position": "Advisory Member",
        "education": "Project Manager, PhD, Post-Doc ",
        "photo": man,
    },
    {
        "id": 2,
        "name": "Vamsi",
        "position": "Frontend with React Mentor",
        "education": "Computer Science Graduate from IIIT",
        "photo": man,
    },
    {
        "id": 3,
        "name": "Kalyan",
        "position": "DevOps with AWS Mentor",
        "education": "Computer Science Graduate from IIIT",
        "photo": man,
    },
    {
        "id": 4,
        "name": "Sandeep",
        "position": "Product Manager",
        "education": "IIT - Dhanbad, IIM - Ahmedabad",
        "photo": man
    },
    {
        "id": 5,
        "name": "Anand Kumar",
        "position": "Flutter Mentor",
        "education": "Computer Science Graduate from IIIT",
        "photo": man
    },
    {
        "id": 6,
        "name": "Sukumar",
        "position": "Java Architect",
        "education": "Java Architect, 16+ Years of Experience in IT Industry",
        "photo": man,
    },
    {
        "id": 7,
        "name": "Lavanya",
        "position": "DataScience and Machine Learning Mentor",
        "education": "Computer Science graduate from IIT - Indore",
        "photo": women
    },
    {
        "id": 8,
        "name": "Arjun",
        "position": "Data Structures and Algorithms Mentor",
        "education": "Computer Science Graduate from IIIT",
        "photo": man,
    },
    {
        "id": 9,
        "name": "Uday",
        "position": "Cyber Security Expert and Mentor",
        "education": "Computer Science Graduate from IIIT",
        "photo": man,
    },
    {
        "id": 10,
        "name": "Jagadeesh",
        "position": "Automation Testing Expert and Mentor",
        "education": "Computer Science Graduate from IIIT",
        "photo": man,
    },


]



function Team() {

    useEffect(() => {
        window.scrollTo(0, 0)
    })

    return (
        <div className="team-page">
            <h1>Our Team</h1>
            <div className="team-members">
                {teamMembers.map((member) => (
                    <div key={member.id} className="team-member">
                        <img src={member.photo} alt={member.name} />
                        <h2>{member.name}</h2>
                        <p>{member.position}</p>
                        <p>{member.education}</p>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default Team