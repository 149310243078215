import React from 'react'
import './Aws.css'
import aws from '../../assests/images/aws.png'



function Aws() {

    function cta() {
        window.open('https://tally.so/r/n9ZGWE', '_blank')
    }

    return (
        <div className="landing-page">
            {/* <header className="header">
                <h1>DevOps with AWS</h1>
                <p>Learn from an Amazon Employee</p>
            </header> */}
            <section className="hero">
                <div className="hero-content">
                    <h2>Accelerate Your DevOps Journey With Free Demo Session</h2>
                    <p>Join us to master the art of DevOps with Amazon Web Services (AWS) By Amazon Employee.</p>
                    <button className="cta-button" onClick={cta}>Register Here For Free Demo Class</button>
                </div>
                <div className="hero-image">
                    <img src={aws} alt="AWS Logo" />
                </div>
            </section>
            <section className="features">
                <div className="feature-section">
                    <div className="feature">
                        <h3>Expert Guidance</h3>
                        <p>Benefit from the experience of an Amazon employee who specializes in DevOps and AWS.</p>
                    </div>
                    <div className="feature">
                        <h3>Hands-on Labs</h3>
                        <p>Practice your skills with hands-on labs and real-world scenarios.</p>
                    </div>
                    <div className="feature">
                        <h3>Certification Prep</h3>
                        <p>Prepare for AWS certification exams to validate your skills.</p>
                    </div>
                </div>
                <div className="feature-section">
                    <div className="feature">
                        <h3>Small Batch Size</h3>
                        <p>The batch size is limited to a maximum of 30 students per session.</p>
                    </div>
                    <div className="feature">
                        <h3>Interactive Learning</h3>
                        <p>The course features live interactive learning sessions led by experienced mentors with real-time industry experience.</p>
                    </div>
                    <div className="feature">
                        <h3>Around-the-Clock Support</h3>
                        <p>We offer around-the-clock support for prompt doubt clarification and assistance.</p>
                    </div>
                </div>
                <div className="feature-section">
                    <div className="feature">
                        <h3>Lifetime Access</h3>
                        <p>Participants receive lifetime access to live class notes of DevOps AWS.</p>
                    </div>
                    <div className="feature">
                        <h3>Recordings Available</h3>
                        <p>Participants also gain lifetime access to our live class recordings.</p>
                    </div>
                    <div className="feature">
                        <h3>Project-Based Learning</h3>
                        <p>The course emphasizes hands-on, project-based learning, with a focus on completing over 5 real-time projects.</p>
                    </div>
                </div>
            </section>
            <section className="advantages">
                <h2>Advantages of Learning DevOps with AWS</h2>
                <div className="advantages-list">
                    <div className="advantage-item">
                        <span class="bullet">•</span> Enhanced job prospects and career growth in the DevOps field.
                    </div>
                    <div className="advantage-item">
                        <span class="bullet">•</span> Ability to deploy and manage applications on AWS cloud infrastructure.
                    </div>
                    <div className="advantage-item">
                        <span class="bullet">•</span> In-depth knowledge of AWS services and DevOps practices.
                    </div>
                    <div className="advantage-item">
                        <span class="bullet">•</span> Highly sought-after skills in the tech industry.
                    </div>
                    <div className="advantage-item">
                        <span class="bullet">•</span> Hands-on experience with real-world AWS projects.
                    </div>
                </div>
            </section>
            <section className="employee-stats">
                <h2>Employee Stats</h2>
                <div className="employee-stat">
                    <h3>Trained Employees</h3>
                    <p>Over 100 employees have been trained in DevOps with AWS.</p>
                </div>
                <div className="employee-stat">
                    <h3>Success Stories</h3>
                    <p>Our training program has resulted in a 95% certification success rate.</p>
                </div>
                <div className="employee-stat">
                    <h3>Industry Impact</h3>
                    <p>Our trained employees have contributed to a 30% improvement in project efficiency.</p>
                </div>
            </section>

        </div>

    )
}

export default Aws