import React from 'react'

function Demo() {
    return (
        <div>
            <iframe
                src="https://tally.so/r/n9ZGWE"
                width="100%"
                height="800"
                allowFullScreen
                title="E-LERN Demo"
            ></iframe>
        </div>
    )
}

export default Demo