import React from "react";
import { useNavigate } from 'react-router-dom';
import './CourseCard.css'; // Import the CSS file

const CourseCard = (props) => {
  const navigate = useNavigate();

  const courseDetailPage = () => {
    const { title } = props.item;
    navigate('/overview/' + title);
  }

  const { imgUrl, title, lesson, syllabus, students, desc, discountedPrice, timings } = props.item;

  return (
    <div className="single__course__item" onClick={courseDetailPage} style={{ margin: "10px", padding: "15px", border: "1px solid #e0e0e0" }}>
      <div className="course__img">
        <img src={imgUrl} alt="" className="w-100" style={{ height: "200px" }} />
      </div>

      <div className="course__details">
        <h5 className="course__title">{title}</h5>
        <p style={{ margin: "10px 0", height: "190px", overflow: "auto", padding: "10px" }}>{desc}</p>

        <div className="d-flex justify-content-between align-items-center">
          <p className="lesson">
            <i className="ri-book-open-line"></i> {lesson} + Lessons
          </p>

          <p className="students">
            <i className="ri-user-line"></i> Max {students} Students
          </p>
        </div>

        <div className="d-flex justify-content-between align-items-center">
          <p className="students">
            <i class="ri-time-line"></i> Class Timings : {timings}
          </p>
        </div>
      </div>
      <div className="alert-info" role="alert" style={{ background: "#cce5ff", color: "#000", padding: "5px", margin: "10px 0" }}>
        New Batch Starts From December 1<sup>st</sup> Week
      </div>

      <p style={{ fontSize: "24px", textAlign: 'center', fontWeight: "600" }}>Price : INR &nbsp;
        {/* <del>{actualPrice}</del>  */}
        {discountedPrice}</p>

      <div className="d-flex justify-content-between align-items-center">
        {/* Add additional content here with proper inline styles */}
      </div>

      <a href={syllabus} target="_blank" rel="noreferrer" className="rating" style={{ textAlign: "center", fontSize: "22px", textDecoration: "none", }}>
        Download Course Curriculum
      </a>

      <p className="enroll" style={{ textAlign: "center", margin: "10px 0" }}>
        <a href="#/enroll" style={{ fontSize: "24px" }} rel="noreferrer">Enroll Now For Live classes</a>
      </p>
    </div >
  );
};

export default CourseCard;
