import React from "react";
import { Container, Row, Col, ListGroup, ListGroupItem } from "reactstrap";

import "./footer.css";

const footerQuickLinks = [
  {
    display: "Home",
    url: "/",
  },
  {
    display: "About",
    url: "#/about",
  },
  {
    display: "Courses",
    url: "#/courses",
  },
  {
    display: "Team",
    url: "#/team",
  },
  {
    display: "Register for Demo",
    url: "#/demo",
  },
];

// const footerInfoLinks = [
//   {
//     display: "Privacy Policy",
//     url: "#",
//   },
//   {
//     display: "Membership",
//     url: "#",
//   },

//   {
//     display: "Purchases Guide",
//     url: "#",
//   },

//   {
//     display: "Terms of Service",
//     url: "#",
//   },
// ];

const Footer = () => {
  return (
    <footer className="footer">
      <Container style={{ padding: "2%" }}>
        <Row>
          <Col lg="3" md="6" className="mb-4">
            <h2 className=" d-flex align-items-center gap-1">
              E-LERN
            </h2>

            <div className="follows">
              <p className="mb-0">Follow us on social media</p>
              <span>
                {" "}
                <a href="https://www.facebook.com/profile.php?id=61551049400432" target="_blank" rel="noreferrer" >
                  <i class="ri-facebook-line"></i>
                </a>
              </span>

              <span>
                {" "}
                <a href="https://www.instagram.com/elern.in/" target="_blank" rel="noreferrer" >
                  <i class="ri-instagram-line"></i>
                </a>
              </span>

              <span>
                {" "}
                <a href="https://www.linkedin.com/in/elern-technologies-387a3028b/" target="_blank" rel="noreferrer">
                  <i class="ri-linkedin-line"></i>
                </a>
              </span>

              <span>
                {" "}
                <a href="https://www.youtube.com/channel/UC4nKp-Wxigt2V5kYUsxBugw" target="_blank" rel="noreferrer">
                  <i class="ri-youtube-line"></i>
                </a>
              </span>

              <span>
                {" "}
                <a href="https://twitter.com/elern2023" target="_blank" rel="noreferrer" >
                  <i class="ri-twitter-line"></i>
                </a>
              </span>
            </div>
          </Col>

          <Col lg="3" md="6" className="mb-4">
            <h6 className="fw-bold">Explore</h6>
            <ListGroup className="link__list">
              {footerQuickLinks.map((item, index) => (
                <ListGroupItem key={index} className="border-0 ps-0 link__item">
                  {" "}
                  {item.display === "Register for Demo" ? (<a href={item.url} rel="noreferrer" style={{ color: "MediumSeaGreen" }} >{item.display}</a>) :
                    (<a href={item.url}>{item.display}</a>)}
                </ListGroupItem>
              ))}
            </ListGroup>
          </Col>

          {/* <Col lg="3" md="6" className="mb-4">
            <h6 className="fw-bold">Information</h6>
            <ListGroup className="link__list">
              {footerInfoLinks.map((item, index) => (
                <ListGroupItem key={index} className="border-0 ps-0 link__item">
                  {" "}
                  <a href={item.url}>{item.display}</a>
                </ListGroupItem>
              ))}
            </ListGroup>
          </Col> */}

          <Col lg="3" md="6">
            <h6 className="fw-bold">Get in Touch</h6>

            {/* <p>E-LERN Technologies, Hyderabad</p> */}
            <p>Mobile : +91 7207784667</p>
            <p>WhatsApp : +91 7207784667</p>
            <p>Email : info@elern.in</p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
