import React from "react";
import "./testimonial.css";
import { Container, Row, Col } from "reactstrap";
import Slider from "react-slick";

import img from "../../assests/images/testimonial01.png";

const Testimonials = () => {
  const settings = {
    infinite: true,
    dots: true,
    speed: 500,
    slidesToShow: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToScroll: 1,
  };
  return (
    <section className="marginInMobile">
      <Container>
        <Row>
          <Col lg="10" md="12" className="m-auto">
            <div className="testimonial__wrapper">
              <div className="testimonial__img">
                <img src={img} alt="" className="w-100" />
              </div>

              <div className="testimonial__content" style={{ margin: "5%" }}>
                <h2 className="mb-4">Our Students Voice</h2>

                <Slider {...settings}>

                  <div>
                    <div className="single__testimonial">
                      <h6 className="mb-3 fw-bold">
                       Difference observed in before and after joining this course.
                      </h6>
                      <p>
                      Course content and the way of explanation is very good. 
                      Able to understand each and every topic from scratch.
                      There is a lot of difference observed in my subject knowledge before and after joining the course.
                      </p>

                      <div className="student__info mt-4">
                        <h6 className="fw-bold">Rama Krishna Yathaluru</h6>
                        <p>Frontend with React.js Student</p>
                      </div>
                    </div>
                  </div>

                  <div>
                    <div className="single__testimonial">
                      <h6 className="mb-3 fw-bold">
                        Too good Explanation
                      </h6>
                      <p>
                        Vamsi sir has good knowledge in the subject, his explanation is very good..he is not go forward /jump to other topics untill the our dougts got clarified. A very good platform to learn new things to improve our knowledge.. 
                      </p>

                      <div className="student__info mt-4">
                        <h6 className="fw-bold">Sharmila</h6>
                        <p>Frontend with React.js Student</p>
                      </div>
                    </div>
                  </div>

                  <div>
                    <div className="single__testimonial">
                      <h6 className="mb-3 fw-bold">
                        Teaching is too good!
                      </h6>
                      <p>
                        Mentor Vamsi sir's teaching and doubt clarifications were so good. And his performance in live sessions is too good. It was so helpful for us.
                      </p>

                      <div className="student__info mt-4">
                        <h6 className="fw-bold">Hari Krishna</h6>
                        <p>Frontend with React.js Student</p>
                      </div>
                    </div>
                  </div>

                  <div>
                    <div className="single__testimonial">
                      <h6 className="mb-3 fw-bold">
                        Live sessions are too good!
                      </h6>
                      <p>
                        My mentor's teaching skills are excellent and he handles live sessions with ease and comfort.
                      </p>

                      <div className="student__info mt-4">
                        <h6 className="fw-bold">Yaseen Pasha</h6>
                        <p>Frontend with React.js Student</p>
                      </div>
                    </div>
                  </div>
                </Slider>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Testimonials;
