import React, { useRef } from "react";
import { Container } from "reactstrap";
import "./header.css";
import elern from "../../assests/images/elern.png"

const navLinks = [
  {
    display: "Home",
    url: "/",
  },
  {
    display: "About",
    url: "#/about",
  },

  {
    display: "Courses",
    url: "#/courses",
  },
  {
    display: "Look at our Team",
    url: "#/team",
  },
  {
    display: "Register for Demo",
    url: "#/demo",
  },
];

const Header = () => {
  const menuRef = useRef();

  const menuToggle = () => menuRef.current.classList.toggle("active__menu");

  return (
    <header className="header">
      <Container>
        <div className=" v d-flex align-items-center justify-content-between">
          <div className="logo">
            {/* <h2 className=" d-flex align-items-center gap-1">
              E-LERN
            </h2> */}
            <a href="/">
              <img src={elern} style={{ width: "50%", margin: "3%" }} alt="E-Lern Logo" />
            </a>
          </div>

          <div className="nav d-flex align-items-center gap-5">
            <div className="nav__menu" ref={menuRef} onClick={menuToggle}>
              <ul className="nav__list">
                {navLinks.map((item, index) => (
                  <li key={index} className="nav__item">
                    {item.display === "Register for Demo" ? (<a href={item.url} rel="noreferrer" style={{ color: "MediumSeaGreen" }} >{item.display}</a>) :
                      (<a href={item.url}>{item.display}</a>)}
                  </li>
                ))}
              </ul>
            </div>

            <div className="nav__right">
              <p className="mb-0 d-flex align-items-center gap-2">
                <i class="ri-phone-line"></i> +91 7207784667
              </p>
            </div>
          </div>

          <div className="mobile__menu">
            <span>
              <i class="ri-menu-line" onClick={menuToggle}></i>
            </span>
          </div>
        </div>
      </Container>
    </header>
  );
};

export default Header;
