import React from 'react';
import { HashRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import AboutUs from './components/About-us/AboutUs';
import Courses from './components/Courses-section/Courses';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
// import CourseOverview from './components/Courses-section/CourseOverview';
import ChooseUs from './components/Choose-us/ChooseUs';
import Team from './components/Team/Team';
import Aws from './components/Demo/Aws';
import Demo from './components/Demo/Demo';
import Enroll from './components/Demo/Enroll';
import Guidance from './components/Demo/Guidance';

function App() {
  return (
    <div className="App">
      <Router>
        <Header />
        <Routes>
          <Route path="/awsdemo" element={<Aws />} />
          <Route path="/demo" element={<Demo />} />
          <Route path="/enroll" element={<Enroll />} />
          <Route path="/guidance" element={<Guidance />} />
          <Route path="/team" element={<Team />} />
          <Route path="/courses" element={<Courses />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/overview/:title" element={<ChooseUs />} />
          <Route path="/" element={<Home />} />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
