import React from 'react'

function Guidance() {
    return (
        <div>
            <iframe
                src="https://tally.so/r/nP1JqV"
                width="100%"
                height="1000"
                allowFullScreen
                title="E-LERN Demo"
            ></iframe>
        </div >
    )
}

export default Guidance