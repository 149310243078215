import React from "react";
import { Container, Row, Col } from "reactstrap";
import heroImg from "../../assests/images/hero-img1.png";
import "./hero-section.css";

const HeroSection = () => {
  return (
    <section>
      <Container>
        <Row>
          <Col lg="6" md="6">
            <div className="hero__content">
              <h2 className="mb-4 hero__title">
                {/* Experience the pinnacle of online education, guided by industry titans, and immerse yourself in real-time projects. */}
                Unlock Your Future: Learn from the Best at IITs, IIITs, and IIMs, with Live Online Classes Led by Industry Experts
              </h2>

              <p className="mb-5">
                Learn from Software Engineers with Real-World Experience at Top Companies Like Amazon, Oracle, Zeta, Capgemini, Infosys, and Tech Mahindra.
              </p>
            </div>

          </Col>

          <Col lg="6" md="6">
            <img src={heroImg} alt="" className="w-100 hero__img" />
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default HeroSection;
