import React from "react";
import { Container, Row, Col } from "reactstrap";
import "./features.css";

const FeatureData = [
  {
    title: "Quick Learning",
    desc: "We will help you learn technologies in 3 months to get you top notch job at Software Industry.",
    icon: "ri-draft-line",
  },

  {
    title: "All Time Support",
    desc: "We will help you resolve your doubts in no time. we are just Whatsapp message away to clear your doubts",
    icon: "ri-discuss-line",
  },

  {
    title: "Certification",
    desc: "To provide authenticity to your leanring, after completion of this course you are going to have course completion certificate from us",
    icon: "ri-contacts-book-line",
  },
];

// you can also use concept of props for this component
const Features = () => {
  return (
    <section>
      <Container style={{ marginTop: "5%" }}>
        <h2>Why you choose us</h2>
        <Row style={{ marginTop: "5%" }}>
          {FeatureData.map((item, index) => (
            <Col lg="4" md="6" key={index}>
              <div className="single__feature text-center px-4">
                <h2 className="mb-3">
                  <i class={item.icon}></i>
                </h2>
                <h6>{item.title}</h6>
                <p>{item.desc}</p>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
};

export default Features;
