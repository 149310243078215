import React, { Fragment } from "react";
import HeroSection from "../components/Hero-Section/HeroSection";
// import CompanySection from "../components/Company-section/Company";

import AboutUs from "../components/About-us/AboutUs";
import Courses from "../components/Courses-section/Courses";
// import ChooseUs from "../components/Choose-us/ChooseUs";
import Features from "../components/Feature-section/Features";
// import FreeCourse from "../components/Free-course-section/FreeCourse";

import Testimonials from "../components/Testimonial/Testimonials";

import Newsletter from "../components/Newsletter/Newsletter";

const Home = () => {

  return (
    <Fragment style={{ margin: "10px" }}>
      <HeroSection />
      {/* <CompanySection /> */}
      <AboutUs />
      <Courses />
      {/* <ChooseUs /> */}
      <Features />
      {/* <FreeCourse /> */}
      <Newsletter />
      <Testimonials />
      {/* <Newsletter /> */}
    </Fragment>
  );
};

export default Home;
