import React from 'react';

// Define the component
function CourseOverview(props) {
    // CSS styles
    const containerStyle = {
        // border: '1px solid #ccc',
        // padding: '10px',
        maxWidth: '100%',
        margin: '0 auto',
        // backgroundColor: 'rgba(226, 248, 248, 0.664)',
    };

    const headingStyle = {
        fontSize: '1.8em',
        color: '#333',
        marginBottom: '10px',
        marginLeft: '2%',
        textAlign: "center"
    };

    const paragraphStyle = {
        fontSize: '1.1em',
        lineHeight: '1.4',
        margin: '2%',
    };

    const instructorStyle = {
        fontWeight: 'bold',
        marginBottom: '5px',
    };

    const linkStyle = {
        color: '#0074cc',
        textDecoration: 'none',
        fontWeight: 'bold',
        marginLeft: '10px', // Adjusted margin-left
        margin: '2%', // Adjusted margin
    };

    return (
        <div style={containerStyle}>
            {/* Course Description */}
            <p style={paragraphStyle}>{props.data.description1}</p>

            {/* Course Highlights */}
            <ul style={{ paddingLeft: '20px' }}>
                {props.data.courseHighlights.map((feature, index) => (
                    <li key={index} style={paragraphStyle}>
                        <span><b>{feature.name}</b></span> <span>{feature.desc}</span>
                    </li>
                ))}
            </ul>

            {/* Instructor Information */}
            <h2 style={headingStyle} >Instructor: <span style={instructorStyle}>{props.data.instructor}</span></h2>

            {/* Course Duration */}
            <h3 style={headingStyle}>Course Duration: {props.data.duration}</h3>

            {/* Timings */}
            <h3 style={headingStyle}>Timings: {props.data.timings}</h3>

            {/* Course Features */}
            <h2 style={{ margin: "3%" }}>Our Features</h2>
            <ul style={{
                // paddingLeft: '20px',
                // textAlign: 'center'
            }}>
                {
                    props.data.features.map((feature, index) => (
                        <li key={index} style={paragraphStyle}>{feature.feature}</li>
                    ))
                }
            </ul>

            {/* Syllabus Link */}
            <p style={{ textAlign: 'center', fontSize: '36px' }}> <a href={props.data.syllabusLink} style={linkStyle} target="_blank" rel="noreferrer">
                Click Here Download Course Curriculum
            </a></p>

            {/* Additional Course Description */}
            <p style={paragraphStyle}>{props.data.description2}</p>

        </div >
    );
}

export default CourseOverview;
